import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import background from "../images/banner2.jpg";
import {Container,Col,Row} from 'react-bootstrap'
import logo1 from '../images/logo1.jpg'
import {TiThumbsUp} from 'react-icons/ti'
import {ImUser} from 'react-icons/im'
import {RiMoneyDollarBoxLine} from 'react-icons/ri'
import {BsCalendar} from 'react-icons/bs'

const SecondPage = () => (
  <Layout>
    <SEO title="Uberuns" />
      <div className="background-img-about" style={{ backgroundImage: `url(${background})`}}>
      <div header-about-uns><h1 className="header-about-uns">swissartmaler</h1></div>
      </div>
      <Container  fluid="md" className="about-startpage">
         <Row>
          <Col sm={6} md={6}>
          <h3 className="header-about">Willkomen bei der Swissartmaler</h3>
          <p className="paragraph">Wir sind eine mittelständische Zürich-Glarner Unternehmer mit proffesionelle Mitarbeitenden. Unsere Qualität basiert 
          auf unseren drei Fachabteilungen Malerei, Gipserarbeiten und Isolieren. Desweiteren führen wir seit mehreren Jahren Sanierungen im kleinen sowie 
          im grossen Rahmen durch und sind somit der ideale Bauleiter für Renovationsarbeiten in Wohnungen sowie in Liegenschaften.Erfahrung und 
          Vertrauen – Mit bewährten Partnerfirmen 
          bieten wir massgeschneiderte Lösungen an.Als Kunde werden Sie individuell,persönlich wie auch kompetent betereut und beraten.</p>
          <h6 className="h6-uber-uns">Alles aus einer Hand</h6>
          <p className="paragraph">Um ihren zeit – und Arbeit saufwand zu optimieren sowie zu minimieren, sind wir ihr direkter Ansprechpartner für sämtliche 
          Fragen und Wünsche.Durch unser breites und tiefes Know-How im Renovation sberreich sowie die Zusammenarbeit mit unseren Partnerfirmen können wir 
          ihnen von kleinen Reparaturen bis hin zu Komplettsanierungen zur Seite stehen. Wir sorgen für die Koordination,Organisation sowie den termingrechten 
          und reibungslosen Ablauf auf der Baustelle.</p>
          </Col>
          <Col className="img-start" sm={6} md={6}>
          <img className="img-startpage" src={logo1} />
          </Col>
         </Row>
      </Container>
      <Container className="fliper">
        <Row>
          <Col xs={6} md={4} sm={2}>
           <div className="flip-container" ontouchstart="this.classList.toggle('hover');">
	          <div className="flipper">
	    	    <div className="front">
			        <h5><TiThumbsUp/>Qualität der Dienstleistungen</h5>
		         </div>
		        <div className="back">
			       <p>Wir bieten qualitativ hochwertige Dienstleistungen und eine hohe Professionalität.</p>
		         </div>
	           </div>
            </div>
          </Col>
          <Col xs={6} md={4} sm={2}>
           <div className="flip-container" ontouchstart="this.classList.toggle('hover');">
	          <div className="flipper">
	    	    <div className="front">
            <h5><ImUser/>Profi Team</h5>
		         </div>
		        <div className="back">
			       <p>Unser Team ist gut ausgebildet und in der Malerei zertifiziert.</p>
		         </div>
	           </div>
            </div>
          </Col>
        </Row><br/>
        <Row>
          <Col xs={6} md={4} sm={2}>
           <div className="flip-container" ontouchstart="this.classList.toggle('hover');">
	          <div className="flipper">
	    	    <div className="front">
			        <h5><RiMoneyDollarBoxLine/>Faire Preise</h5>
		         </div>
		        <div className="back">
			        <p>Swissartmaler bietet günstige Preise für Dienstleistungen.</p>
		         </div>
	           </div>
            </div>
          </Col>
          <Col xs={6} md={4} sm={2}>
           <div className="flip-container" ontouchstart="this.classList.toggle('hover');">
	          <div className="flipper">
	    	    <div className="front">
			        <h5><BsCalendar/>Hohe Effizienz</h5>
		         </div>
		        <div className="back">
			       <p>Swissartmaler Team führt Aktivitäten in einem professionellen und sehr schnell.</p>
		         </div>
	           </div>
            </div>
          </Col>
        </Row>

      </Container>
    
  </Layout>
)

export default SecondPage
